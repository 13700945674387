@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body {
    overscroll-behavior-y: contain;
    height: 100%;
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  transition: .3s background-color;
}

#root {
  height: 100%;
}

.theme-light {
  background-color: white;
}

.theme-light p {
  color: theme('colors.black');
}

.theme-light .secondary-text {
  color: theme('colors.gray.700');
}

.theme-dark {
  background-color: #0f172a;
}

.theme-dark p {
  color: theme('colors.white');
}

.theme-dark .secondary-text {
  color: theme('colors.gray.400');
}

/* * {
  transition: background-color .2s, color .3s;
} */

h2 {
  letter-spacing: -1px;
}

/* 
@keyframes scaleWobble {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
} */